.datacardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
}

.pincodecardContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.datacardCard {
  align-self: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.2s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-height: fit-content;
  min-width: fit-content;
  padding: 0px 20px 0px 30px;
  margin: 0% 2% 1% 1%;
  transition-duration: 0.2s;
}

.datacardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-color: crimson;
  padding-top: 10px;
  margin-top: 1%;
  height: fit-content;
}

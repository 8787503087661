.backupcodes-container {
  align-items: center;
  padding-bottom: 15px;
}

.backupcodes-arrow-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.backupcodes-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  margin-left: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 3px;
}

.down {
  margin-bottom: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.backupcodes-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.backupcodes-codes {
  display: flex;
  flex-direction: column;
}

.lockview-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

.datacards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (max-width: 750px) {
  .datacards {
    justify-content: center;
  }
}

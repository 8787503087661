.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #607567;
  height: 8vh;
  align-items: center;
}

.header-text {
  color: #ffffff;
  font-size: 30px;
  text-align: left;
  margin: 0% 1% 0% 1%;
}

.logo {
  height: 85%;
  display: block;
  text-align: center;
  margin: 0% 1% 0% 1%;
}

@media (max-width: 700px) {
  .header {
    flex-direction: row;
    align-items: center;
  }

  .header-text {
    visibility: hidden;
    display: none;
  }

  .logo {
    margin: 0% 1% 0% 45%;
  }
}

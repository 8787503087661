.keystatus-container {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.keystatus-indicator {
  padding: 10px 10px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition-duration: 0.2s;
}

.keystatus-indicator:hover {
  cursor: pointer;
  transition-duration: 0s;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 2px 2px;
}

.button-basic {
  background-color: #607567;
  border: none;
  color: #ffffff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 2px;
  transition-duration: 0.2s;
  margin: 1% 1% 1% 1%;
  max-width: 60%;
}

.button-basic:hover {
  background-color: #4d5e52; /* Green */
  color: white;
  cursor: pointer;
}

.button-basic:active {
  background-color: #3a463e; /* Green */
  color: white;
}

.button-basic:disabled {
  background-color: #cfd6d1; /* Green */
  color: white;
}

@media (max-width: 1100px) {
  .button-basic {
    font-size: 16px;
  }
}

@media (max-width: 900px) {
  .button-basic {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .button-basic {
    font-size: 12px;
  }
}

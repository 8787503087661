.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: static;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modal-box {
  color: black;
  display: block;
  background: white;
  min-width: 30%;
  max-width: 70%;
  max-height: 70%;
  padding: 1rem;
  margin: 10rem;
  border-radius: 5px;
}

.datacardTexts {
  font-size: 23px;
  text-align: left;
  display: block;
  padding-right: 20px;
}

@media (max-width: 1100px) {
  .datacardTexts {
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .datacardTexts {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .datacardTexts {
    font-size: 16px;
  }
}
